<template>
  <div v-if="!isNavOpen">
    <button class="menu" @click="toggleNavOpen">
      <img class="menu_img" :src="`${CDN}/front/img/menu.webp`" width="20" height="16" alt="メニュー">
    </button>
  </div>
  <div v-else>
    <div>
      <button class="menu" @click="toggleNavOpen">
        <img class="menu_img" :src="`${CDN}/front/img/close.webp`" width="16" height="16" alt="閉じる">
      </button>
    </div>
    <LogoBar />
    <div>
      <nav class="nav">
        <router-link :class="['nav_level2', {'nav_now': isNow('Index')}]" :to="{ name: 'Index' }">
          トップ
        </router-link>
        <hr class="nav_hr">
        <router-link class="nav_level1" :to="{ name: 'Index', hash: '#feature' }" @click="toggleNavOpen">
          アプリの特長
        </router-link>
        <router-link :class="['nav_level3', {'nav_now': isNow('Brands')}]" :to="{ name: 'Brands' }">
          銘柄ラインアップ
        </router-link>
        <a class="nav_level3" href="https://aisarekigyo.stockpoint.jp" target="_blank">
          愛され企業さーちへ
          <img class="navIcon" :src="`${CDN}/front/img/target_blank_gray.webp`" width="20" height="20" alt="">
        </a>
        <router-link :class="['nav_level1', {'nav_now': isNow('News') || isNow('NewsDetail')}]" :to="{ name: 'News' }">
          お知らせ
        </router-link>
        <a class="nav_level1" href="https://spcheer-help.stockpoint.jp/hc/ja" target="_blank">
          よくあるご質問
          <img class="navIcon" :src="`${CDN}/front/img/target_blank_gray.webp`" width="20" height="20" alt="">
        </a>
        <hr class="nav_hr">
        <a class="nav_level4" href="https://spcheer-help.stockpoint.jp/hc/ja/requests/new" target="_blank">
          お問い合わせ
          <img class="navIcon" :src="`${CDN}/front/img/target_blank_gray.webp`" width="20" height="20" alt="">
        </a>
        <a class="nav_level4" :href="`${CDN}/static/terms.html`" target="_blank">
          会員規約・プライバシーポリシー
          <img class="navIcon" :src="`${CDN}/front/img/target_blank_gray.webp`" width="20" height="20" alt="">
        </a>
        <a class="nav_level4" href="https://stockpoint.co.jp" target="_blank">
          運営会社
          <img class="navIcon" :src="`${CDN}/front/img/target_blank_gray.webp`" width="20" height="20" alt="">
        </a>
      </nav>
      <div v-if="'2022/10/03 10:00:00' <= getNowDateString($route.query)" class="store">
        <div class="store_text">
          アプリダウンロード
        </div>
        <div class="iconList">
          <a class="iconLink" :href="`${APP_STORE_URL}`" target="_blank">
            <img class="iconLink_img" :src="`${CDN}/front/img/app_store.webp`" width="129" height="47" alt="App Storeからダウンロード">
          </a>
          <a class="iconLink" :href="`${GOOGLE_PLAY_URL}`" target="_blank">
            <img class="iconLink_img" :src="`${CDN}/front/img/google_play.webp`" width="158" height="47" alt="Google Playで手に入れよう">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.menu {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 20px 18px;
  width: 56px;
  height: 56px;
  background-color: var(--col-sub);
  border-bottom-left-radius: 10px;
  line-height: 1;
}

.menu_img {
  max-height: 16px;
}

.nav {
  padding: 30px 30px 150px;
}

.nav_hr {
  margin-bottom: 30px;
  background-color: var(--col-gray);
  height: 1px;
  border: none;
}

.nav_level1,
.nav_level2,
.nav_level3,
.nav_level4 {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.nav_level1,
.nav_level2 {
  font-size: 18px;
  font-weight: 600;
}

.nav_level3,
.nav_level4 {
  font-size: 16px;
  font-weight: 300;
}

.nav_level1,
.nav_level3 {
  margin-bottom: 30px;
}

.nav_level3 {
  margin-left: 20px;
}

.nav_level2,
.nav_level4 {
  margin-bottom: 20px;
}

.nav_now {
  color: var(--col-sub);
}

.navIcon {
  margin-left: 5px;
}

.store {
  background-color: var(--col-sub-light);
  padding: 30px;
}

.store_text {
  font-weight: 600;
  padding-bottom: 10px;
}

.iconList {
  display: inline-flex;
}

.iconLink:not(:last-child) {
  margin-right: 20px;
}

.iconLink_img {
  max-height: 47px;
}
</style>

<script>
import LogoBar from '@/components/LogoBar.vue'
import { getNowDateString } from '@/lib/dateModules'

export default {
  components: {
    LogoBar,
  },

  props: {
    isNavOpen: {
			type: Boolean,
      default: false,
    },

    toggleNavOpen: {
			type: Function,
      default: function() {},
    },
  },

  data: function() {
    return {
      APP_STORE_URL: APP_STORE_URL,
      GOOGLE_PLAY_URL: GOOGLE_PLAY_URL,
      CDN: CDN,
    }
  },

  methods: {
    isNow: function(name) {
      return name == this.$route.name
    },

    getNowDateString: getNowDateString,
  },
}
</script>
