<template>
  <router-view/>
</template>

<script>
export default {
  mounted: function() {
    this.updateMeta(this.$route)
  },

  watch: {
    $route() {
      this.updateMeta(this.$route)
    }
  },

  methods: {
    updateMeta: function(route) {
      let title = route.meta.title ? `${route.meta.title} | ${APP_TITLE}` : APP_TITLE
      let desc = route.meta.desc ? route.meta.desc : APP_DESC

      document.title = title
      document.querySelector("meta[property='og:title']")?.setAttribute('content', title)
      document.querySelector("meta[name='twitter:title']")?.setAttribute('content', title)
      document.querySelector("meta[name='twitter:title']")?.setAttribute('content', title)
      document.querySelector("meta[name='description']")?.setAttribute('content', desc)
      document.querySelector("meta[property='og:description']")?.setAttribute('content', desc)
      document.querySelector("meta[name='twitter:description']")?.setAttribute('content', desc)
    }
  },
}
</script>

<style>
@import "@/assets/base.css";
</style>
