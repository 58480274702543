<template>
  <div>
    <div class="icon">
      Powerd by
      <img class="icon_img" :src="`${CDN}/front/img/sp_logo.webp`" width="144" height="30" alt="STOCK POINT">
    </div>
    <p class="text">
      StockPoint for CHEER証券（当サービスおよび当サイト）はSTOCK POINT株式会社が運営しております。
    </p>
    <div class="link_area">
      <a class="link" href="https://stockpoint.co.jp" target="_blank">
        運営会社
        <img class="link_icon" :src="`${CDN}/front/img/target_blank_sub.webp`" width="20" height="20" alt="">
      </a>
    </div>
  </div>
</template>

<style scoped>
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 20px;
}

.icon_img {
  margin-left: 10px;
  max-width: 145px;
}

.text {
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  padding-bottom: 20px;
  max-width: 335px;
  margin: auto;
  text-align: left;
}

.link_area {
  text-align: center;
}

.link {
  font-size: 14px;
  font-weight: 400;
  color: var(--col-sub);
  display: inline-flex;
  align-items: center;
}

.link_icon {
  margin-left: 10px;
  max-width: 20px;
}
</style>

<script>
import Title from '@/components/Title.vue'
import LinkButton from '@/components/LinkButton.vue'

export default {
  components: {
    Title,
    LinkButton,
  },

  data: function() {
    return {
      CDN: CDN,
    }
  },
}
</script>
