<template>
  <div class="contents">
    <Nav :isNavOpen="isNavOpen" :toggleNavOpen="toggleNavOpen" />
    <Header v-show="!isNavOpen" :isIndex="true" />
    <div v-show="!isNavOpen">
      <Notice />
      <div class="area1 contents_inner">
        <div class="times">
          <img class="times_img" :src="`${CDN}/front/img/times.webp`" width="335" height="60" alt="">
          <div class="times_quiz">クイズ</div>
          <div class="times_pointInv">ポイント運用</div>
        </div>
        <Title>
          新感覚アプリで<br>ポイント運用を始めよう！
        </Title>
      </div>

      <Feature class="area1 contents_inner" id="feature" />
      <HowTo class="area2 contents_inner" />
      <LineUp class="area1 contents_inner" />
      <Aisare class="area2 contents_inner" />
      <div class="area1 contents_inner">
        <Title :isUnderline="true">
          お知らせ
        </Title>
        <NewsList :isTop="true" :newsList="getFilterList(newsList)" />
        <LinkButton
          text="お知らせ一覧"
          modify="borderSub"
          url="/#/news"
          rightIcon="arrow_right_sub"
          :isTargetBlank="false"
        />
      </div>
      <Download class="area3 contents_inner" />
      <Support class="area2 contents_inner" />
      <hr class="hr">
      <Share class="area2 contents_inner" />
      <hr class="hr">
      <PowerdBy class="area2 contents_inner" />
    </div>
    <Footer v-show="!isNavOpen" />
  </div>
</template>

<style scoped>
.area1 {
  background-color: var(--col-sub-light);
  text-align: center;
}

.area1:first-child {
  padding-bottom: 0;
  margin-bottom: -40px;
}

.area2 {
  background-color: #fff;
  text-align: center;
}

.area3 {
  background-color: var(--col-main-light);
  text-align: center;
}

.times {
  width: 335px;
  height: 60px;
  margin: auto;
  margin-bottom: 20px;
}

.times_img {
  max-width: 335px;
}

.times_quiz,
.times_pointInv {
  color: #fff;
  font-size: 20px;
  font-weight: 200;
  line-height: 1;
  position: absolute;
  top: 20px;
}

.times_quiz {
  left: 44px;
}

.times_pointInv {
  right: 37px;
}

.twitter {
  background-color: #fff;
  border-radius: 5px;
  padding: 20px 10px 10px;
}

.twitter_title {
  font-weight: 600;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  width: 100%;
}

.twitter_icon {
  max-width: 20px;
  margin-right: 8px;
}

.campaign {
  border: 2px solid var(--col-black);
  border-radius: 10px;
  background-color: var(--col-yellow-light);
  padding: 50px 20px 30px;
}

.campaign_icon {
  max-width: 255px;
  padding-bottom: 30px;
}

.campaign_text {
  font-weight: 600;
  padding-bottom: 20px;
  text-align: left;
}

.campaign_textEmph {
  color: var(--col-main);
  font-weight: 600;
}

.hr {
  height: 1px;
  border: none;
  line-height: 1px;
  background-color: var(--col-gray);
  margin: 0;
}
</style>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Nav from '@/components/Nav.vue'
import LinkButton from '@/components/LinkButton.vue'
import Title from '@/components/Title.vue'
import Notice from '@/components/Notice.vue'
import Feature from '@/components/Feature.vue'
import HowTo from '@/components/HowTo.vue'
import LineUp from '@/components/LineUp.vue'
import Aisare from '@/components/Aisare.vue'
import NewsList from '@/components/NewsList.vue'
import Download from '@/components/Download.vue'
import Support from '@/components/Support.vue'
import Share from '@/components/Share.vue'
import PowerdBy from '@/components/PowerdBy.vue'
import { getList } from '@/lib/listModules'
import { getNowDateString } from '@/lib/dateModules'

export default {
  components: {
    Header,
    Footer,
    Nav,
    LinkButton,
    Title,
    Notice,
    Feature,
    HowTo,
    LineUp,
    Aisare,
    NewsList,
    Download,
    Support,
    Share,
    PowerdBy,
  },

  data: function() {
    return {
      CDN: CDN,
      isNavOpen: false,
      newsList: [],
      MAX_NEWS_LIST: 3,
    }
  },

  mounted: async function() {
    this.addTweetScript()
    this.newsList = await getList('news.json')
  },

  watch: {
    $route() {
      this.addTweetScript()
    }
  },

  methods: {
    toggleNavOpen: function() {
      this.isNavOpen = !this.isNavOpen
    },

    addTweetScript: function() {
      const widgetUrl = 'https://platform.twitter.com/widgets.js'
      document.querySelector(`script[src='${widgetUrl}']`)?.remove()

      let tweetScript = document.createElement('script')
      tweetScript.setAttribute('src', widgetUrl)
      tweetScript.setAttribute('async', 'true')
      document.head.appendChild(tweetScript)
    },

    getFilterList: function(list) {
      const nowDate = getNowDateString(this.$route.query)
      const listExceptedFuture = list.filter(
        (news) => !news.releaseDate || news.releaseDate <= nowDate
      )
      return listExceptedFuture.slice(0, this.MAX_NEWS_LIST)
    },

    getNowDateString: getNowDateString,
  },
}
</script>
