<template>
  <div>
    <div>
      <img class="icon" :src="`${CDN}/front/img/app_icon.webp`" width="96" height="96" alt="">
    </div>
    <Title :isUnderline="true">
      StockPoint for CHEER証券とは？
    </Title>
    <div class="title">
      アプリの特長
    </div>
    <p class="text">
      クイズに答えて米国株の知識を蓄えながらポイント運用できるアプリ！<br>
      CHEER証券に口座開設をして本アプリと連携すれば運用したポイントを株としてGETできる！
    </p>
    <div class="list">
      <div class="list_card">
        <div class="card">
          <h3 class="card_title">
            投資クイズ
          </h3>
          <div class="card_inner">
            <img class="inner_img" :src="`${CDN}/front/img/feature_01_img.webp`" width="295" height="182" alt="">
            <p class="inner_text">
              クイズを楽しんでいるうちに、米国株の知識が身に付きます！
            </p>
            <ul class="inner_list">
              <li>投資クイズは1日1問出題</li>
              <li>0:00に新しいクイズが登場</li>
            </ul>
            <div class="inner_captures">
              <img :src="`${CDN}/front/img/feature_capture01.webp`" width="160" height="258" alt="">
            </div>
            <hr class="inner_hr">
            <p class="inner_text">
              クイズは簡単な２択問題。回答後に表示される解説を読んで知識を深めよう！
            </p>
            <div class="inner_captures">
              <img :src="`${CDN}/front/img/feature_capture02.webp`" width="140" height="260" alt="">
              <img :src="`${CDN}/front/img/feature_capture03.webp`" width="140" height="260" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="list_card">
        <div class="card">
          <h3 class="card_title">
            ポイント運用
          </h3>
          <div class="card_inner">
            <img class="inner_img" :src="`${CDN}/front/img/feature_02_img.webp`" width="205" height="170" alt="">
            <p class="inner_text">
              ポイントを使ってお好きな米国銘柄を購入。実際の銘柄と連動したポイント運用を体験できます！
            </p>
            <div class="inner_captures">
              <img :src="`${CDN}/front/img/feature_capture04.webp`" width="295" height="215" alt="">
            </div>
            <hr class="inner_hr">
            <p class="inner_text">
              StockPoint for CHEER証券では、 厳選された米国銘柄をラインアップ。 テーマや人気順など様々な方法で運用先をお選びいただけます。
            </p>
            <div class="inner_captures">
              <img :src="`${CDN}/front/img/feature_capture05.webp`" width="140" height="290" alt="">
              <img :src="`${CDN}/front/img/feature_capture06.webp`" width="140" height="290" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.icon {
  text-align: center;
}

.title {
  margin-top: -10px;
  color: var(--col-sub);
  padding-bottom: 30px;
  font-weight: 600;
}

.text {
  font-weight: 600;
  text-align: left;
  padding-bottom: 30px;
}

.list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.list_card {
  flex: 0 0 335px;
  z-index: 1;
  margin-top: 56px;
}

@media screen and (max-width: 767px) {
  .list {
    display: block;
  }

  .list_card {
    max-width: 335px;
    margin-left: auto;
    margin-right: auto;
  }

  .list_card:nth-child(1) {
    padding-bottom: 30px;
  }
}

.list_card::before {
  content: "";
  width: 107px;
  height: 106px;
  position: absolute;
  top: -56px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}

.list_card:nth-child(1)::before {
  background: url("https://cdn.stockpoint.jp/cheer/front/img/feature_01.webp");
  background-size: 107px;
}

.list_card:nth-child(2)::before {
  background: url("https://cdn.stockpoint.jp/cheer/front/img/feature_02.webp");
  background-size: 107px;
}

.card {
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
}

.list_card:nth-child(1) .card {
  border: 2px solid var(--col-sub);
}

.list_card:nth-child(2) .card {
  border: 2px solid var(--col-main);
}

.card_title {
  color: #fff;
  font-size: 18px;
  line-height: 1;
  font-weight: 600;
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
}

.list_card:nth-child(1) .card_title {
  background: var(--col-sub) url("https://cdn.stockpoint.jp/cheer/front/img/dot_sub.webp");
  background-size: 331px;
}

.list_card:nth-child(2) .card_title {
  background: var(--col-main) url("https://cdn.stockpoint.jp/cheer/front/img/dot_main.webp");
  background-size: 331px;
}

.card_inner {
  padding: 20px 20px 30px;
}

.inner_img {
  max-width: 295px;
}

.inner_text {
  font-weight: 600;
  text-align: left;
  margin-bottom: 30px;
}

.inner_comment {
  font-weight: 300;
  text-align: left;
}

.inner_list {
  margin-bottom: 20px;
  padding: 15px 20px;
  background-color: var(--col-gray2);
  border-radius: 5px;
}

.inner_list li {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
}

.inner_list li:before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: var(--col-sub);
  border-radius: 50%;
  margin-right: 10px;
  line-height: 28px;
}

.inner_captures {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 30px;
}

.inner_captures img:not(:first-child) {
  margin-left: 15px;
}

.inner_hr {
  max-width: 120px;
  height: 4px;
  border: none;
  border-radius: 2px;
  line-height: 4px;
  background-color: var(--col-main);
  margin-bottom: 30px;
}

.list_card:nth-child(1) .inner_hr {
  background-color: var(--col-sub);
}

.inner_caution {
  margin-bottom: 30px;
  background-color: var(--col-pink-light);
  border-radius: 5px;
  padding: 20px 20px 0;
}

.inner_caution h4 {
  display: inline-flex;
  font-weight: 600;
  margin-bottom: 20px;
}

.inner_caution h4 img {
  margin-right: 6px;
}

.inner_caution p {
  font-size: 14px;
  font-weight: 300;
  padding-bottom: 20px;
  text-align: left;
}
</style>

<script>
import Title from '@/components/Title.vue'
import { getNowDateString } from '@/lib/dateModules'

export default {
  components: {
    Title,
  },

  data: function() {
    return {
      CDN: CDN,
    }
  },

  methods: {
    getNowDateString: getNowDateString,
  },
}
</script>
