<template>
  <div v-if="noticeList.length > 0">
    <div
      class="noticeRow"
      v-for="(notice, index) in noticeList"
      :key="index"
    >
      <a class="notice" :href="notice.url" :target="notice.isTargetBlank ? '_blank' : ''">
        <span class="notice_title">
          {{ notice.title }}
        </span>
        <img class="notice_icon" :src="`${CDN}/front/img/arrow_right_pink.webp`" width="24" height="24" alt="">
      </a>
    </div>
  </div>
</template>

<style scoped>
.noticeRow {
  padding: 0 20px;
  margin-bottom: 20px;
}

.noticeRow:first-child {
  margin-top: 20px;
}

.notice {
  border: 2px solid var(--col-pink);
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  white-space: initial;
}

.notice_title {
  color: var(--col-pink);
  font-weight: 300;
}

.notice:hover .notice_title {
  text-decoration: underline;
}

.notice_icon {
  margin-left: 10px;
}
</style>

<script>
import { getList } from '@/lib/listModules'

export default {
  data: function() {
    return {
      CDN: CDN,
      noticeList: [],
    }
  },

  mounted: async function() {
    this.noticeList = await getList('notice.json')
  },
}
</script>
