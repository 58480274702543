<template>
  <div>
    <h1 class="title">
      <slot />
    </h1>
  </div>
</template>

<style scoped>
.title {
  font-size: 28px;
  font-weight: 600;
  line-height: 1;
  padding-bottom: 40px;
}
</style>
