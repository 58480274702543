<template>
  <div class="sns">
    <div class="sns_title">
      このページをシェア
    </div>
    <div class="sns_link">
      <a class="link" :href="`https://twitter.com/share?url=${getURL()}`" target="_blank">
        <img :src="`${CDN}/front/img/twitter.webp`" width="29" height="30" alt="X">
      </a>
      <a class="link" :href="`https://www.facebook.com/share.php?u=${getURL()}`" target="_blank">
        <img :src="`${CDN}/front/img/facebook.webp`" width="30" height="30" alt="Facebook">
      </a>
      <a class="link" :href="`https://social-plugins.line.me/lineit/share?url=${getURL()}`" target="_blank">
        <img :src="`${CDN}/front/img/line.webp`" width="30" height="30" alt="LINE">
      </a>
    </div>
  </div>
</template>

<style scoped>
.sns_title {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;
  text-align: center;
}

.sns_link {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.link {
  padding-left: 10px;
  padding-right: 10px;
}

.link img {
  max-height: 30px;
}
</style>

<script>
export default {
  props:{
    shareURL: {
      type: String,
      default: '',
    },
  },

  data: function() {
    return {
      CDN: CDN,
    }
  },

  methods: {
    getURL: function() {
      return this.shareURL == '' ? location.href : this.shareURL
    },
  },
}
</script>
