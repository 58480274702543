import axios from 'axios'

export async function getList(listFileName) {
  const url = `/static/list/${listFileName}`

  const res = await axios
    .get(url)
    .then(function (res) {
      return res.data
    })
    .catch(function (e) {
      console.log(e)
      return false
    })

  if (!res) {
    return []
  } else {
    return res
  }
}
