<template>
  <div class="logoBar">
    <router-link class="logo" :to="{ name: 'Index' }">
      <img class="logo_img" :src="`${CDN}/front/img/spcheer_logo.webp`" width="224" height="30" alt="StockPoint for CHEER証券">
    </router-link>
  </div>
</template>

<style scoped>
.logoBar {
  background-color: var(--col-sub-light);
  padding: 13px 20px;
  height: 56px;
}

.logo_img {
  max-width: 224px;
}
</style>

<script>
export default {
  data: function() {
    return {
      CDN: CDN,
    }
  },
}
</script>
