<template>
  <div v-if="isIndex">
    <MainVisual />
    <Store />
  </div>
  <div v-else>
    <LogoBar />
  </div>
</template>

<style scoped>
</style>

<script>
import MainVisual from '@/components/MainVisual.vue'
import Store from '@/components/Store.vue'
import LogoBar from '@/components/LogoBar.vue'

export default {
  components: {
    MainVisual,
    Store,
    LogoBar,
  },

  props: {
    isIndex: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
