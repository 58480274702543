import { createRouter, createWebHashHistory } from 'vue-router'
import Index from '../views/Index.vue'
import Brands from '../views/Brands.vue'
import News from '../views/News.vue'
import NewsDetail from '../views/NewsDetail.vue'

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
  },
  {
    path: '/brands',
    name: 'Brands',
    meta: {
      title: '銘柄ラインアップ',
      desc: '銘柄ラインアップページです。',
    },
    component: Brands,
  },
  {
    path: '/news',
    name: 'News',
    meta: {
      title: 'お知らせ',
      desc: 'お知らせ一覧ページです。',
    },
    component: News,
  },
	{
		path: '/news/:yymmdd_nn',
    name: 'NewsDetail',
    meta: {
      title: 'お知らせ詳細',
      desc: 'お知らせ詳細ページです。',
    },
    component: NewsDetail,
	},
]

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ el: to.hash, behavior: 'smooth' })
        }, 500)
      })
    } else {
      return { top: 0, left: 0 }
    }
  },
  routes
})

export default router
