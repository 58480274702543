<template>
  <div class="store">
    <div class="storeList">
      <div class="iconList">
        <a class="iconLink" :href="`${APP_STORE_URL}`" target="_blank">
          <img class="iconLink_img" :src="`${CDN}/front/img/app_store.webp`" width="129" height="47" alt="App Storeからダウンロード">
        </a>
        <a class="iconLink" :href="`${GOOGLE_PLAY_URL}`" target="_blank">
          <img class="iconLink_img" :src="`${CDN}/front/img/google_play.webp`" width="161" height="48" alt="Google Playで手に入れよう">
        </a>
      </div>
      <div class="qr">
        <img
          :src="`${CDN}/front/img/QR.webp`"
          width="103"
          height="103"
          alt="https://cheer.stockpoint.jp/static/redirect/store.html"
        >
      </div>
    </div>
    <div class="storeText">
      QRコードからもインストールできます
    </div>
  </div>
</template>

<style scoped>
.store {
  background-color: var(--col-main);
}

.storeList {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.iconList {
  display: inline-flex;
}

@media screen and (max-width: 767px) {
  .iconList {
    flex-direction: column;
    align-items: flex-start;
  }
}

.iconLink:not(:last-child) {
  margin-right: 20px;
}

@media screen and (max-width: 767px) {
  .iconLink:not(:last-child) {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

.iconLink_img {
  max-height: 48px;
}

.qr {
  width: 103px;
  height: 103px;
  background-color: #fff;
  margin-left: 20px;
  border-radius: 5px;
  overflow: hidden;
}
.qr img {
  width: 100%;
}

.storeText {
  font-size: 12px;
  line-height: 20px;
  font-weight: 300;
  margin-top: -15px;
  margin-left: 170px;
  padding-bottom: 20px;
}

@media screen and (max-width: 767px) {
  .storeText {
    margin-top: 0;
    margin-left: 0;
    padding-top: 10px;
    text-align: center;
  }
}

.soon {
  text-align: center;
  padding: 20px 0 15px;
}

.soon_logo {
  max-width: 253px;
}
</style>

<script>
import { getNowDateString } from '@/lib/dateModules'

export default {
  components: {
  },

  data: function() {
    return {
      APP_STORE_URL: APP_STORE_URL,
      GOOGLE_PLAY_URL: GOOGLE_PLAY_URL,
      CDN: CDN,
    }
  },

  methods: {
    getNowDateString: getNowDateString,
  },
}
</script>
