<template>
  <div>
    <Title :isUnderline="true">
      ダウンロード
    </Title>
    <div class="title">
      最短10秒で今すぐはじめよう
    </div>
    <div>
      <img class="icon" :src="`${CDN}/front/img/download.webp`" width="375" height="345" alt="">
    </div>
    <div class="storeList">
      <div class="iconList">
        <a class="iconLink" :href="`${APP_STORE_URL}`" target="_blank">
          <img class="iconLink_img" :src="`${CDN}/front/img/app_store.webp`" width="129" height="47" alt="App Storeからダウンロード">
        </a>
        <a class="iconLink" :href="`${GOOGLE_PLAY_URL}`" target="_blank">
          <img class="iconLink_img" :src="`${CDN}/front/img/google_play.webp`" width="161" height="48" alt="Google Playで手に入れよう">
        </a>
      </div>
      <div class="qr">
        <img
          :src="`${CDN}/front/img/QR.webp`"
          width="103"
          height="103"
          alt="https://cheer.stockpoint.jp/static/redirect/store.html"
        >
      </div>
    </div>
    <div class="storeText">
      QRコードからもインストールできます
    </div>
    <p class="text">
      ダウンロード後はTwitterやFacebookのSNS認証でアカウント登録を行うだけで完了！
    </p>
  </div>
</template>

<style scoped>
.title {
  color: var(--col-main);
  font-weight: 600;
  padding-bottom: 10px;
}

.icon {
  max-width: 375px;
}

.store {
  background-color: var(--col-main);
}

.storeList {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.iconList {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}

.iconLink:not(:last-child) {
  margin-bottom: 10px;
}

.iconLink_img {
  max-height: 48px;
}

.qr {
  width: 103px;
  height: 103px;
  background-color: #fff;
  margin-left: 20px;
  border-radius: 5px;
  overflow: hidden;
}
.qr img {
  width: 100%;
}

.storeText {
  font-size: 12px;
  line-height: 20px;
  font-weight: 300;
  padding-bottom: 30px;
  text-align: center;
}

.soon {
  text-align: center;
  padding: 20px 0 15px;
  background-color: var(--col-main);
  margin-left: -40px;
  margin-right: -40px;
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .soon {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.soon_logo {
  max-width: 253px;
}

.text {
  width: 328px;
  font-weight: 300;
  margin: auto;
  text-align: left;
}
</style>

<script>
import Title from '@/components/Title.vue'
import { getNowDateString } from '@/lib/dateModules'

export default {
  components: {
    Title,
  },

  data: function() {
    return {
      APP_STORE_URL: APP_STORE_URL,
      GOOGLE_PLAY_URL: GOOGLE_PLAY_URL,
      CDN: CDN,
    }
  },

  methods: {
    getNowDateString: getNowDateString,
  },
}
</script>
