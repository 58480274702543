<template>
  <div>
    <Title :isUnderline="true">
      お客様サポート
    </Title>
    <div class="link">
      <LinkButton
        text="よくあるご質問"
        modify="gray"
        url="https://spcheer-help.stockpoint.jp/hc/ja"
        rightIcon="target_blank_sub"
        :isTargetBlank="true"
        :isSquare="true"
      />
    </div>
    <div class="link">
      <LinkButton
        text="お問い合わせフォーム"
        modify="gray"
        url="https://spcheer-help.stockpoint.jp/hc/ja/requests/new"
        rightIcon="target_blank_sub"
        :isTargetBlank="true"
        :isSquare="true"
      />
    </div>
  </div>
</template>

<style scoped>
.link {
  padding-top: 10px;
}
</style>

<script>
import Title from '@/components/Title.vue'
import LinkButton from '@/components/LinkButton.vue'

export default {
  components: {
    Title,
    LinkButton,
  },
}
</script>
