<template>
  <div class="footer">
    <button :class="['toTop', {'toTop-fixed': isFixed}]" @click="toTop()">
      <img :src="`${CDN}/front/img/arrow_top_black.webp`" width="24" height="24" alt="TOPへ">
    </button>
    <nav class="nav">
      <router-link :class="['nav_level2', {'nav_now': isNow('Index')}]" :to="{ name: 'Index' }">
        トップ
      </router-link>
      <hr class="nav_hr">
      <router-link class="nav_level1" :to="{ name: 'Index', hash: '#feature' }">
        アプリの特長
      </router-link>
      <router-link :class="['nav_level3', {'nav_now': isNow('Brands')}]" :to="{ name: 'Brands' }">
        銘柄ラインアップ
      </router-link>
      <a class="nav_level3" href="https://aisarekigyo.stockpoint.jp" target="_blank">
        愛され企業さーちへ
        <img class="navIcon" :src="`${CDN}/front/img/target_blank_gray.webp`" width="20" height="20" alt="">
      </a>
      <router-link :class="['nav_level1', {'nav_now': isNow('News') || isNow('NewsDetail')}]" :to="{ name: 'News' }">
        お知らせ
      </router-link>
      <a class="nav_level1" href="https://spcheer-help.stockpoint.jp/hc/ja" target="_blank">
        よくあるご質問
        <img class="navIcon" :src="`${CDN}/front/img/target_blank_gray.webp`" width="20" height="20" alt="">
      </a>
      <hr class="nav_hr">
      <a class="nav_level4" href="https://spcheer-help.stockpoint.jp/hc/ja/requests/new" target="_blank">
        お問い合わせ
        <img class="navIcon" :src="`${CDN}/front/img/target_blank_gray.webp`" width="20" height="20" alt="">
      </a>
      <a class="nav_level4" :href="`${CDN}/static/terms.html`" target="_blank">
        会員規約・プライバシーポリシー
        <img class="navIcon" :src="`${CDN}/front/img/target_blank_gray.webp`" width="20" height="20" alt="">
      </a>
      <div class="nav_logo">
        <router-link class="logo" :to="{ name: 'Index' }">
          <img class="logo_img" :src="`${CDN}/front/img/spcheer_logo2.webp`" width="146" height="106" alt="StockPoint for CHEER証券">
        </router-link>
      </div>
    </nav>
    <div class="copyright">Copyright &copy; STOCKPOINT Inc.</div>
  </div>
</template>

<style scoped>
.footer {
  background-color: var(--col-gray2);
}

.toTop {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: 2px solid var(--col-black);
  background-color: var(--col-sub);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -25px;
  right: 10px;
  z-index: 1;
}

.toTop-fixed {
  position: fixed;
  top: initial;
  bottom: 10px;
}

.nav {
  padding: 40px 40px 0;
}

@media screen and (max-width: 767px) {
  .nav {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.nav_hr {
  margin-bottom: 30px;
  background-color: var(--col-gray);
  height: 1px;
  border: none;
}

.nav_level1,
.nav_level2,
.nav_level3,
.nav_level4 {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 15px;
}

.nav_level1,
.nav_level2 {
  font-weight: 600;
}

.nav_level3,
.nav_level4 {
  font-weight: 300;
  opacity: 0.7;
}

.nav_level1,
.nav_level3 {
  margin-bottom: 30px;
}

.nav_level3 {
  margin-left: 20px;
}

.nav_level2,
.nav_level4 {
  margin-bottom: 20px;
}

.nav_now {
  opacity: 0.5;
}

.navIcon {
  margin-left: 5px;
}

.nav_logo {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

.logo_img {
  max-width: 146px;
}

.copyright {
  font-size: 10px;
  opacity: 0.4;
  text-align: center;
  padding-bottom: 50px;
}
</style>

<script>
export default {
  data: function() {
    return {
      CDN: CDN,
      isFixed: false,
    }
  },

  mounted: function() {
    window.addEventListener('scroll', this.onScroll)
  },

  methods: {
    isNow: function(name) {
      return name == this.$route.name
    },

    onScroll: function() {
      const isScroolTop = window.scrollY < 50
      const dispFooterButton = window.innerHeight - 25
      const footerFromTop = this.$el.getBoundingClientRect().y
      this.isFixed = !isScroolTop && dispFooterButton <= footerFromTop
    },

    toTop: function() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
  },
}
</script>
