<template>
  <div>
    <div>
      <img class="icon" :src="`${CDN}/front/img/aisare_icon.webp`" width="70" height="66" alt="">
    </div>
    <Title :isUnderline="true">
      愛され企業を応援しよう
    </Title>
    <p class="text">
      企業情報紹介ページからその会社を応援したり、その会社や商品のすごいところなどを投稿することができます。<br>
      自分でポイント運用している会社はもちろん、他の銘柄企業の素敵なところを知る事もできます。<br>
      素敵なコメントへのプレゼントキャンペーン開催中！ぜひチェックしてください！
    </p>
    <div>
      <img class="logo" :src="`${CDN}/front/img/aisare_logo.webp`" width="217" height="49" alt="">
    </div>
    <LinkButton
      text="愛され企業さーちへ"
      modify="sub"
      url="https://aisarekigyo.stockpoint.jp"
      rightIcon="target_blank_white"
      :isTargetBlank="true"
    />
  </div>
</template>

<style scoped>
.icon {
  max-width: 70px;
  margin-bottom: 20px;
}

.text {
  text-align: left;
  font-weight: 300;
  padding-bottom: 30px;
}

.logo {
  max-width: 217px;
  margin-bottom: 20px;
}
</style>

<script>
import Title from '@/components/Title.vue'
import LinkButton from '@/components/LinkButton.vue'

export default {
  components: {
    Title,
    LinkButton,
  },

  data: function() {
    return {
      CDN: CDN,
    }
  },
}
</script>
