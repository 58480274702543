<template>
  <div>
    <Title :isUnderline="true">
      アプリの使い方
    </Title>
    <div class="list">
      <div class="inner">
        <div class="inner_num">1</div>
        <div class="inner_title">
          毎日出題されるクイズに挑戦！
        </div>
        <img class="inner_img" :src="`${CDN}/front/img/howto_01.webp`" width="140" height="260" alt="">
        <p class="inner_text">
          クイズは簡単２択方式！<br>
          正解と思う方をタップして結果を見よう。
        </p>
        <hr class="inner_hr">
      </div>
      <div class="inner">
        <div class="inner_num">2</div>
        <div class="inner_title">
          ポイント運用ができる！
        </div>
        <img class="inner_img" :src="`${CDN}/front/img/howto_02.webp`" width="140" height="229" alt="">
        <p class="inner_text">
          好きな銘柄や気になる銘柄を探してポイント運用してみよう。
        </p>
        <hr class="inner_hr">
      </div>
      <div class="inner">
        <div class="inner_num">3</div>
        <div class="inner_title">
          CHEER証券口座で株式として受け取る
        </div>
        <img class="inner_img" :src="`${CDN}/front/img/howto_04.webp`" width="140" height="194" alt="">
        <p class="inner_text">
          本アプリよりCHEER証券に口座開設。ID連携すれば運用したポイントを株として受け取れます。
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.inner {
  max-width: 335px;
  margin: 0 auto;
}

.inner_num {
  width: 48px;
  height: 48px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background-color: var(--col-sub);
  font-size: 28px;
  line-height: 1;
  color: #fff;
  margin-bottom: 10px;
}

.inner_title {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 20px;
}

.inner_img {
  margin-bottom: 20px;
}

.inner_text {
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  padding-bottom: 30px;
}

.inner:last-child .inner_text {
  padding-bottom: 0;
}

.inner_hr {
  max-width: 120px;
  height: 4px;
  border: none;
  border-radius: 2px;
  line-height: 4px;
  background-color: var(--col-sub);
  margin-bottom: 30px;
}
</style>

<script>
import Title from '@/components/Title.vue'

export default {
  components: {
    Title,
  },

  data: function() {
    return {
      CDN: CDN,
    }
  },
}
</script>
