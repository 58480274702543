<template>
  <div>
    <div>
      <img class="icon" :src="`${CDN}/front/img/lineup.webp`" width="67" height="67" alt="">
    </div>
    <Title :isUnderline="true">
      豊富な銘柄ラインアップ
    </Title>
    <div class="title">
      全世界でお馴染みの米国有名企業の株式
    </div>
    <LinkButton
      text="銘柄ラインアップへ"
      modify="sub"
      url="/#/brands"
      rightIcon="arrow_right_white"
    />
  </div>
</template>

<style scoped>
.icon {
  max-width: 67px;
  padding-bottom: 10px;
}

.title {
  font-weight: 600;
  padding-bottom: 30px;
}
</style>

<script>
import Title from '@/components/Title.vue'
import LinkButton from '@/components/LinkButton.vue'

export default {
  components: {
    Title,
    LinkButton,
  },

  data: function() {
    return {
      CDN: CDN,
    }
  },
}
</script>
