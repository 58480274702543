<template>
  <div class="contents">
    <Nav :isNavOpen="isNavOpen" :toggleNavOpen="toggleNavOpen" />
    <Header v-show="!isNavOpen" />
    <div v-show="!isNavOpen" v-html="article" class="contents_inner article" />
    <div v-show="!isNavOpen">
      <hr class="hr">
      <div class="contents_inner">
        <Share class="share" />
        <LinkButton
          text="お知らせ一覧へもどる"
          modify="borderSub"
          url="/#/news"
          leftIcon="arrow_left_sub"
          :isTargetBlank="false"
        />
      </div>
    </div>
    <Footer v-show="!isNavOpen" />
  </div>
</template>

<style scoped>
.share {
  margin-bottom: 40px;
}

.hr {
  height: 1px;
  border: none;
  line-height: 1px;
  background-color: var(--col-gray);
  margin: 0;
}

.article :deep(h1) {
  font-size: 28px;
  line-height: 32px;
  font-weight: 600;
  padding-bottom: 20px;
}

.article :deep(h2) {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  padding-bottom: 20px;
  color: var(--col-sub);
  border-bottom: 1px solid var(--col-gray);
  margin-bottom: 20px;
}

.article :deep(h3) {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  padding-bottom: 20px;
}

.article :deep(h4) {
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 20px;
}

.article :deep(div) {
  padding-bottom: 30px;
}

.article :deep(p) {
  font-weight: 300;
  padding-bottom: 30px;
}

.article :deep(img) {
  max-width: 100%;
  max-height: 367px;
}

.article :deep(.img) {
  text-align: center;
}

.article :deep(.date) {
  font-size: 14px;
  color: var(--col-sub);
  margin-right: 8px;
}

.article :deep(.category) {
  background-color: var(--col-gray2);
  color: var(--col-gray3);
  font-size: 12px;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 20px;
  line-height: 1;
  margin-bottom: 10px;
}

.article :deep(.category-important) {
  background-color: var(--col-pink-light);
  color: var(--col-pink);
}

.article :deep(.subColor) {
  color: var(--col-sub);
}

.article :deep(ul) {
  list-style-type: none;
}

.article :deep(ul li) {
  font-weight: 300;
  padding-bottom: 25px;
  padding-left: 25px;
}

.article :deep(ul li::before) {
  content: '・';
  display: inline-flex;
  justify-content: center;
  line-height: 16px;
  margin-left: -25px;
  margin-right: 9px;
  text-align: center;
  height: 16px;
  width: 16px;
}

.article :deep(ol) {
  counter-reset: my-counter;
  list-style-type: none;
}

.article :deep(ol li) {
  font-weight: 300;
  padding-bottom: 25px;
  padding-left: 25px;
}

.article :deep(ol li::before) {
  content: counter(my-counter);
  counter-increment: my-counter;
  display: inline-flex;
  justify-content: center;
  line-height: 16px;
  margin-left: -25px;
  margin-right: 9px;
  text-align: center;
  height: 16px;
  width: 16px;
  border: 1px solid var(--col-black);
  border-radius: 50%;
}

.article :deep(a) {
  color: var(--col-sub);
  line-height: 26px;
  white-space: initial;
  display: inline;
}

.article :deep(.empha) {
  font-weight: bold;
  color: var(--col-pink);
}

.article :deep(.article_listArea) {
  counter-reset: my-counter;
}

.article :deep(.article_list-numberNoCircle::before) {
  content: counter(my-counter) '.';
  counter-increment: my-counter;
  color: var(--col-main);
}

.article :deep(.article_list-numberBracket::before) {
  content: '(' counter(my-counter) ')';
  counter-increment: my-counter;
  color: var(--col-main);
}

.article :deep(.article_compareTableArea) {
  margin-bottom: 30px;
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  border: 1px solid var(--col-black);
}

.article :deep(.article_compareTable) {
  padding-left: 0;
}

.article :deep(.article_compareTable::before) {
  display: none;
}

.article :deep(.article_compareTable:first-child) {
  border-right: 1px solid var(--col-black);
}

.article :deep(.article_compareTable .article_listArea),
.article :deep(.article_compareTable .article_list) {
  padding-bottom: 0;
  overflow: visible;
}

.article :deep(.article_compareTable .article_list-numberNoCircle::before),
.article :deep(.article_compareTable .article_list-numberBracket::before) {
  color: var(--col-black);
  border: none;
}

.article :deep(.compareTable_head) {
  font-weight: bold;
  border-bottom: 1px solid var(--col-black);
  padding: 5px 10px;
}

.article :deep(.compareTable_body) {
  padding: 5px 10px;
}

.article :deep(.compareTable_body-diff) {
  font-weight: bold;
  font-style: italic;
}

@media screen and (max-width: 807px) {
  .article :deep(.article_compareTableArea) {
    display: block;
  }

  .article :deep(.article_compareTable:first-child) {
    border-right: none;
    border-bottom: 1px solid var(--col-black);
  }
}

.article :deep(table) {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

.article :deep(table th),
.article :deep(table td) {
  border: 1px solid #ccc;
  padding: 10px;
}
</style>

<script>
import axios from 'axios'
import Header from '@/components/Header.vue'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Share from '@/components/Share.vue'
import LinkButton from '@/components/LinkButton.vue'

export default {
  components: {
    Header,
    Nav,
    Footer,
    Share,
    LinkButton,
  },

  data: function() {
    return {
      isNavOpen: false,
      article: '',
      yymmdd_nn: '',
    }
  },

  mounted: async function() {
    this.article = await this.getArticleHTML()
  },

  // ニュース記事からニュース記事へのリンクはupdatedで本文を更新する
  updated: async function() {
    this.article = await this.getArticleHTML()
  },

  methods: {
    toggleNavOpen: function() {
      this.isNavOpen = !this.isNavOpen
    },

    getArticleHTML: async function() {
      if (this.yymmdd_nn !== this.$route.params.yymmdd_nn) {
        this.yymmdd_nn = this.$route.params.yymmdd_nn
        const res = await axios
          .get(`/static/news/${this.yymmdd_nn}.html`)
          .then(function (res) {
            return res.data
          })
          .catch(function (e) {
            console.log(e)
            return false
          })

        if (!res) {
          return '<div class="notFound">ページが見つかりません。<br>恐れ入りますが最初からやり直してください。</div>'
        } else {
          return res
        }
      } else {
        return this.article
      }
    }
  },
}
</script>
