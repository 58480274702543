<template>
  <div class="contents">
    <Nav :isNavOpen="isNavOpen" :toggleNavOpen="toggleNavOpen" />
    <Header v-show="!isNavOpen" />
    <div v-show="!isNavOpen" class="contents_inner">
      <PageTitle>銘柄ラインアップ</PageTitle>
      <ul>
        <li class="brand" v-for="brand in brandList" :key="brand.code">
          {{ brand.name }}
          <span>（{{ brand.code }}）</span>
        </li>
      </ul>
    </div>
    <div v-show="!isNavOpen">
      <hr class="hr">
      <div class="contents_inner">
        <Share />
      </div>
    </div>
    <Footer v-show="!isNavOpen" />
  </div>
</template>

<style scoped>
.hr {
  height: 1px;
  border: none;
  line-height: 1px;
  background-color: var(--col-gray);
  margin: 0;
}

.brand {
  font-weight: 300;
  padding-bottom: 20px;
}

.brand span {
  font-size: 14px;
  font-weight: 300;
  color: var(--col-gray3);
}
</style>

<script>
import Header from '@/components/Header.vue'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Share from'@/components/Share.vue'
import PageTitle from '@/components/PageTitle.vue'
import { getList } from '@/lib/listModules'

export default {
  components: {
    Header,
    Nav,
    Footer,
    PageTitle,
    Share,
  },

  data: function() {
    return {
      isNavOpen: false,
      brandList: [],
    }
  },

  mounted: async function() {
    this.brandList = await getList('brands.json?20230530')
  },

  methods: {
    toggleNavOpen: function() {
      this.isNavOpen = !this.isNavOpen
    },
  },
}
</script>
