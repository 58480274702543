<template>
  <div :class="['newsList', { 'newsList-top': isTop }]">
    <div class="notFound" v-if="newsList.length == 0">
      該当するお知らせはありません。
    </div>
    <div v-else v-for="(news, index) in newsList" :key="news.yymmdd_nn">
      <h2 class="year" v-if="!isTop && (index == 0 || newsList[index - 1].yymmdd_nn.slice(0, 2) != news.yymmdd_nn.slice(0, 2))">
        20{{ news.yymmdd_nn.slice(0, 2) }}年
      </h2>
      <a
        :href="news.url"
        :target="news.isNewTab ? '_blank' : ''"
        :class="['news', news.url == null || news.url == '' ? 'noLink' : '']"
      >
        <img
          v-if="!isTop"
          class="thumbnail"
          :src="news.thumbnailImg != '' ? news.thumbnailImg : `${CDN}/front/img/thumbnail.webp`"
          width="120"
          height="120"
          alt=""
        />
        <div>
          <div v-if="!isTop">
            <span :class="['category', { 'category-important': news.category == '重要なお知らせ' }]">
              {{ news.category }}
            </span>
          </div>
          <div class="date">
            {{ isTop ? getDotDate(news.yymmdd_nn) : getJapaneseDate(news.yymmdd_nn.slice(0, 6))}}
          </div>
          <div class="news_title">
            {{ news.title }}
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<style scoped>
.newsList {
  padding-bottom: 30px;
}

.notFound {
  padding: 40px 0;
}

.newsList-top {
  border-top: 1px solid var(--col-gray);
}

.year {
  padding-top: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--col-gray);
  color: var(--col-sub);
  font-size: 20px;
  line-height: 1;
  font-weight: 600;
}

.news {
  display: flex;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid var(--col-gray);
}

.news:hover,
.news:focus {
  text-decoration: none;
}

.noLink {
  pointer-events: none;
}

.thumbnail {
  width: 120px;
  height: 120px;
  margin-right: 20px;
}

.category {
  background-color: var(--col-gray2);
  color: var(--col-gray3);
  font-size: 12px;
  display: inline-block;
  padding: 5px 15px;
  border-radius: 20px;
  line-height: 1;
  margin-bottom: 10px;
}

.category-important {
  background-color: var(--col-pink-light);
  color: var(--col-pink);
}

.date {
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  color: var(--col-sub);
  margin-bottom: 10px;
}

.news_title {
  font-weight: 300;
  white-space: initial;
}

.news:hover .news_title,
.news:focus .news_title,
.news:hover .date,
.news:focus .date {
  text-decoration: underline;
}
</style>


<script>
import { getDotDate, getJapaneseDate } from '@/lib/dateModules'

export default {
  props:{
    newsList: {
      type: Array,
      default: [],
    },

    isTop: {
      type: Boolean,
      default: false,
    },
  },

  data: function() {
    return {
      CDN: CDN,
    }
  },

  methods: {
    getDotDate: getDotDate,
    getJapaneseDate: getJapaneseDate,
  },
}
</script>
