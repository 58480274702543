<template>
  <div class="linkButton_area">
    <a :class="`linkButton linkButton-${modify} ${isSquare ? 'linkButton-square' : ''}`" :href="`${url}`" :target="`${isTargetBlank ? '_blank' : ''}`">
      <img
        v-if="leftIcon"
        class="linkButton_icon-left"
        :src="`${CDN}/front/img/${leftIcon}.webp`"
        width="24"
        height="24"
        :alt="`${leftIcon}`"
      >
      {{ text }}
      <img
        v-if="rightIcon"
        class="linkButton_icon-right"
        :src="`${CDN}/front/img/${rightIcon}.webp`"
        width="24"
        height="24"
        :alt="`${rightIcon}`"
      >
    </a>
  </div>
</template>

<style scoped>
.linkButton_area {
  text-align: center;
}

.linkButton {
  display: flex;
  width: 100%;
  max-width: 295px;
  height: 50px;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--col-black);
  border-radius: 25px;
  margin-left: auto;
  margin-right: auto;
  color: var(--col-black);
  background-color: var(--col-yellow);
  box-shadow: 0 3px 0 var(--col-black);
  font-weight: 600;
}

.linkButton-square {
  border-radius: 10px;
  border: 1px solid var(--col-gray);
  background-color: var(--col-gray2);
  max-width: 335px;
  box-shadow: none;
  height: 60px;
}

.linkButton-sub {
  background-color: var(--col-sub);
  box-shadow: none;
  border: none;
  color: #fff;
}

.linkButton-borderSub {
  background-color: #fff;
  box-shadow: none;
  border: 1px solid var(--col-sub);
  color: var(--col-sub);
}

.linkButton-disabled {
  background-color: var(--col-gray);
  opacity: 0.5;
  box-shadow: none;
  pointer-events: none;
}

.linkButton_icon-left {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 12px;
  margin: auto;
  max-width: 24px;
}

.linkButton_icon-right {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 12px;
  margin: auto;
  max-width: 24px;
}
</style>

<script>
export default {
  props:{
    url: {
      type: String,
      default: "",
    },

    leftIcon: {
      type: String,
      default: "",
    },

    rightIcon: {
      type: String,
      default: "",
    },

    text: {
      type: String,
      default: "",
    },

    modify: {
      type: String,
      default: "",
    },

    isTargetBlank: {
      type: Boolean,
      default: false,
    },

    isSquare: {
      type: Boolean,
      default: false,
    },
  },

  data: function() {
    return {
      CDN: CDN,
    }
  },

  methods: {
    isTB: function() {
      return this.isTargetBlank
    },
  },
}
</script>
