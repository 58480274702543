<template>
  <div class="mainVisual">
    <div class="mainVisual_inner">
      <div class="logo">
        <div class="logo_title">ポイント運用アプリ</div>
        <img class="logo_top" :src="`${CDN}/front/img/logo.webp`" width="303" height="103" alt="StockPoint for CHEER証券">
        <img class="logo_bottom" :src="`${CDN}/front/img/thankyou.webp`" width="204" height="165" alt="">
      </div>
      <div class="device">
        <img class="device_img" :src="`${CDN}/front/img/device_screen.webp`" width="491" height="491" alt="">
      </div>
    </div>
  </div>
</template>

<style scoped>
.mainVisual {
  background: linear-gradient(to bottom, var(--col-sub-light) 40%, #ffffff 100%);
}

.mainVisual_inner {
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
  margin-right: -20px;
  overflow: hidden;
}

.logo {
  text-align: center;
  margin-top: 73px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}

.logo_title {
  font-size: 18px;
  font-weight: 600;
  z-index: 1;
  margin-bottom: -12px;
}

.logo_top {
  max-width: 303px;
}

.logo_bottom {
  margin-top: 57px;
  max-width: 204px;
}

.device {
  margin-left: -98px;
  margin-bottom: -86px;
  z-index: 1;
}

.device_img {
  max-width: 491px;
}

.store {
  background-color: var(--col-main);
}

@media screen and (max-width: 767px) {
  .mainVisual {
    display: block;
    margin-right: 0;
    text-align: center;
  }

  .mainVisual_inner {
    margin-right: 0;
  }

  .logo {
    margin-top: 40px;
    width: 100%;
    z-index: 1;
  }

  .logo_top {
    max-width: 266px;
  }

  .logo_bottom {
    margin-top: 50px;
    margin-bottom: 55px;
    margin-left: -135px;
  }

  .device {
    margin-left: 0;
    margin-bottom: 0;
    z-index: 2;
    position: absolute;
    bottom: -75px;
    right: -140px;
    left: 0;
    margin: auto;
  }

  .device_img {
    max-width: 350px;
  }
}
</style>

<script>
export default {
  data: function() {
    return {
      CDN: CDN,
    }
  },
}
</script>
