import { createApp } from 'vue'
import { createGtm } from '@gtm-support/vue-gtm'
import App from './App.vue'
import router from './router'

const app = createApp(App)

app.use(router)
if ( location.hostname == 'cheer.stockpoint.jp' ) {
  app.use(createGtm({
    id: 'GTM-PGM563Z',
    vueRouter: router,
  }))
} else if ( location.hostname == 'cheer.dev2.stockpoint.jp' ) {
  app.use(createGtm({
    id: 'GTM-N7K76XQ',
    vueRouter: router,
  }))
}
app.mount('#app')
