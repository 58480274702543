<template>
  <div class="contents">
    <Nav :isNavOpen="isNavOpen" :toggleNavOpen="toggleNavOpen" />
    <Header v-show="!isNavOpen" />
    <div v-show="!isNavOpen" class="contents_inner">
      <PageTitle>お知らせ</PageTitle>
      <div>
        <select class="nav" v-model="category" aria-label="カテゴリ">
          <option value="">すべてのお知らせ</option>
          <option value="お知らせ">お知らせ</option>
          <option value="プレスリリース">プレスリリース</option>
          <option value="重要なお知らせ">重要なお知らせ</option>
        </select>
      </div>
      <NewsList :newsList="getFilterList(newsList, category)" :isTop="false" />
    </div>
    <Footer v-show="!isNavOpen" />
  </div>
</template>

<style scoped>
.nav {
	-webkit-appearance: none;
	appearance: none;
	text-overflow: ellipsis;
	display: block;
	width: 100%;
	padding: 20px;
  background: var(--col-gray2) url("https://cdn.stockpoint.jp/cheer/front/img/icon_tri_down.webp") no-repeat top 50% right 15px / 10px;
}
</style>

<script>
import Header from '@/components/Header.vue'
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import NewsList from '@/components/NewsList.vue'
import PageTitle from '@/components/PageTitle.vue'
import { getList } from '@/lib/listModules'
import { getNowDateString } from '@/lib/dateModules'

export default {
  components: {
    Header,
    Nav,
    Footer,
    NewsList,
    PageTitle
  },

  data: function() {
    return {
      CDN: CDN,
      isNavOpen: false,
      newsList: [],
      category: ''
    }
  },

  mounted: async function() {
    this.newsList = await getList('news.json')
  },

  methods: {
    toggleNavOpen: function() {
      this.isNavOpen = !this.isNavOpen
    },

    getFilterList: function(list, category) {
      const nowDate = getNowDateString(this.$route.query)
      const listExceptedFuture = list.filter(
        (news) => !news.releaseDate || news.releaseDate <= nowDate
      )
      if (category == '' || listExceptedFuture.length == 0) {
        return listExceptedFuture
      } else {
        return listExceptedFuture.filter(news => news.category == category)
      }
    },
  },
}
</script>
