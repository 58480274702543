<template>
  <div>
    <h2 :class="[
      'title',
      { 'title-colorSub': isColorSub },
      { 'title-underline': isUnderline },
    ]">
      <slot />
    </h2>
  </div>
</template>

<style scoped>
.title {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  padding-bottom: 40px;
}

.title-colorSub {
  color: var(--col-sub);
}

.title-underline {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  padding-bottom: 20px;
}

.title-underline::before {
  content: "";
  width: 100%;
  height: 10px;
  z-index: -1;
  position: absolute;
  top: 23px;
  left: 0;
  background: repeating-linear-gradient(
    45deg,
    var(--col-sub2),
    var(--col-sub2) 1px,
    transparent 1px,
    transparent 4px
  );
}
</style>

<script>
export default {
  props:{
    isColorSub: {
      type: Boolean,
      default: false,
    },

    isUnderline: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
